html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: Roboto, sans-serif;
}

.menu-content {
  background-color: #F7F9FA;
  /* full height minus approx headers height */
  height: calc(100vh - 140px) !important;
  padding-top: 2em;
}
